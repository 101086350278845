import { useEffect, useState } from 'react';
import ScreenshotOutlinedIcon from '@mui/icons-material/ScreenshotOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

interface Stats {
    tinder_messages: number;
    bumble_messages: number;
    onlyfans_messages: number;
    total_screenshots: number;
    total_convos: number;
    total_users: number;
}

export function StatsComponent() {
    const [data, setData] = useState<Stats | null>(null);

    useEffect(() => {
        // todo: Replace useStats with your actual fetching logic
        async function fetchStats() {
            // Simulated fetch call
            const response = await fetch(
                'https://app.thetext.app/api/public/stats',
            );
            const data = await response.json();
            setData(data);
        }
        fetchStats();
    }, []);

    const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
    const formatNumber = (number: number = 0) => formatter.format(number);

    if (!data) return null;

    return (
        <article
            className="flex justify-evenly gap-4 bg-gradient-to-b from-gray-700 to-slate-900 py-4 text-sm text-white"
            title="Live stats from the Dating Studio community, updated regularly."
        >
            <p
                className="hidden items-center gap-2 sm:flex"
                title="Tinder messages"
            >
                Tinder {formatNumber(data.tinder_messages)}
                <TextsmsOutlinedIcon className="w-4 p-1" />
            </p>
            <p
                className="hidden items-center gap-2 md:flex"
                title="Bumble messages"
            >
                Bumble {formatNumber(data.bumble_messages)}
                <TextsmsOutlinedIcon className="w-4 p-1" />
            </p>
            {/* <p
                className="hidden items-center gap-2 sm:flex"
                title="Screenshots uploaded"
            >
                Screenshots {formatNumber(data.total_screenshots)}
                <ScreenshotOutlinedIcon className="w-4 p-1" />
            </p> */}
            <p className="flex items-center gap-2" title="Total conversations">
                Chats {formatNumber(data.total_convos)}
                <ForumOutlinedIcon className="w-4 p-1" />
            </p>
        </article>
    );
}
