import { captureRemixErrorBoundaryError } from '@sentry/remix';
import {
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useRouteError,
} from '@remix-run/react';
import type { LinksFunction } from '@remix-run/server-runtime';
import styles from './tailwind.css?url';
import { Layout as LayoutTemplate } from '~/components/Layout';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <html lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    href="/ds-favicon-black.svg"
                    rel="icon"
                    media="(prefers-color-scheme: light)"
                />
                <link
                    href="/ds-favicon-white.svg"
                    rel="icon"
                    media="(prefers-color-scheme: dark)"
                />
                <link
                    href="/ds-favicon-black.svg"
                    rel="mask-icon"
                    media="(prefers-color-scheme: light)"
                />
                <link
                    href="/ds-favicon-white.svg"
                    rel="mask-icon"
                    media="(prefers-color-scheme: dark)"
                />

                <Meta />
                <Links />
            </head>
            <body>
                {children}
                <ScrollRestoration />
                <Scripts />
                <script
                    defer
                    src="https://mami.dating.studio/script.js"
                    data-website-id="0e1bae23-e5f4-45b1-9f2b-f2e607752e57"
                ></script>
            </body>
        </html>
    );
}

export const ErrorBoundary = () => {
    const error = useRouteError();

    if (process.env.NODE_ENV === 'development') {
        return (
            <div>
                <p>An error occurred: {error.message}</p>
                <pre>{error.stack}</pre>
            </div>
        );
    }

    captureRemixErrorBoundaryError(error);
    return <div>Something went wrong</div>;
};

export default function App() {
    return (
        <LayoutTemplate>
            <Outlet />
        </LayoutTemplate>
    );
}
