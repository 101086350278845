import { Link } from '@remix-run/react';

export function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="p-4 pt-12 text-white sm:p-6 md:pt-28 lg:pt-36">
            <div className="container">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <Link
                            to="/"
                            className="flex items-center"
                            title="Dating Studio by The Text App"
                        >
                            <img
                                src="/icon.svg"
                                className="mr-3 h-24"
                                alt="Dating Studio Logo"
                            />
                            <span className="hidden self-center whitespace-nowrap text-2xl font-semibold">
                                Dating Studio by The Text App
                            </span>
                        </Link>
                    </div>

                    <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold uppercase">
                                Contact
                            </h2>
                            <ul className="text-base-content space-y-4">
                                <li>
                                    <a
                                        href="mailto:hi@dating.studio"
                                        className="hover:underline"
                                    >
                                        Drop us a line
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                                Resources
                            </h2>
                            <ul className="text-base-content space-y-4">
                                <li>
                                    <Link
                                        to="/extension"
                                        className="hover:underline"
                                    >
                                        Chrome Extension
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/chad-ai"
                                        className="hover:underline"
                                    >
                                        Chad AI
                                        <span className="badge badge-primary ml-2">
                                            early access
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold uppercase">
                                Policies
                            </h2>
                            <ul className="text-base-content space-y-4">
                                <li>
                                    <Link
                                        to="/privacy-policy"
                                        className="hover:underline"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/terms-of-service"
                                        className="hover:underline"
                                    >
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/refund-policy"
                                        className="hover:underline"
                                    >
                                        Refund Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-300 sm:mx-auto lg:my-8" />
                <div className="flex flex-row justify-between gap-2 text-gray-400 md:items-start">
                    <div className="text-left">
                        <p>
                            © {currentYear} Dating Studio, a theme by The Text
                            App
                        </p>
                    </div>
                    <p title="with love from Europe">Built with ❤️</p>
                </div>
                <p className="my-4 text-justify text-xs text-gray-400">
                    This site is not endorsed by or affiliated with any dating
                    apps or AI Model companies. Tinder® and Hinge® are the
                    exclusive registered trademark of Match Group, LLC. Bumble®
                    is the exclusive registered trademark of Bumble Holding Ltd.
                    GPT-4® is registered trademark of OpenAI LLC. Claude® is
                    registered trademark of Anthropic PBC.
                </p>
            </div>
        </footer>
    );
}
