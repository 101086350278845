import { Footer } from '~/components/Index/Footer';
import { Navigation } from '~/components/Navigation';
import { StatsComponent } from '~/components/StatsLine';

export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <>
            <StatsComponent />
            <div className="flex min-h-screen flex-col scroll-smooth bg-gradient-to-b from-slate-900 to-stone-950 text-white">
                <Navigation />
                {children}

                <span className="flex flex-1"></span>

                <Footer />
            </div>
        </>
    );
}
